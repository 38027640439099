import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/pages/quem-somos/hero"
import IntelectualIndependency from "../components/pages/quem-somos/intelectual-independency"
import Pillars from "../components/pages/quem-somos/pillars"
import ArticlesSocial from "../components/pages/quem-somos/articles-social"
import { graphql } from "gatsby"
import DefaultProfileImage from "../images/favicon.png"

const QuemSomos = ({ data }) => (
  <Layout headerSize="big">
    <SEO
      title="Quem Somos"
      description="Somos a Apetrecho Digital.
        Estamos aqui para ajudar você a conquistar sua
        independência intelectual e se destacar na Era do conhecimento."
      canonical="quem-somos/"
      jsonld={{
        "@context": "https://schema.org",
        "@type": "AboutPage",
        publisher: {
          "@type": "Organization",
          name: "Apetrecho Digital",
          url: data.site.siteMetadata.siteUrl,
          logo: `${
            data.site.siteMetadata.siteUrl
          }${DefaultProfileImage}`,
        },
        url: `${data.site.siteMetadata.siteUrl}quem-somos/`,
        name: "Quem Somos",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": data.site.siteMetadata.siteUrl,
        },
        description:
          "Somos a Apetrecho Digital. Estamos aqui para ajudar você a conquistar sua independência intelectual e se destacar na Era do conhecimento.",
      }}
    />
    <Hero />
    <IntelectualIndependency />
    <Pillars />
    <ArticlesSocial />
  </Layout>
)

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
        siteUrl
      }
    }
  }
`

export default QuemSomos

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Container from "../../container"
import LifelongLearningImg from "../../../images/quem-somos/lifelong-learning.svg"
import MindsetCrescimentoImg from "../../../images/quem-somos/mentalidade-de-crescimento.svg"
import MetaAprendizadoImg from "../../../images/quem-somos/meta-aprendizado.svg"
import AutodidatismoImg from "../../../images/quem-somos/autodidatismo.svg"

const SC = {}

const Pillars = () => {
  const pillars = [
    {
      image: LifelongLearningImg,
      title: "Lifelong Learning",
      description:
        "É a busca pelo aprendizado de novas habilidades e conhecimentos durante toda a vida, seja por vias formais ou informais.",
      url: "/lifelong-learning-educacao-continuada/",
    },
    {
      image: MindsetCrescimentoImg,
      title: "Mindset de Crescimento",
      description:
        "Forma de pensar baseada na crença de que qualquer um é capaz de aprender qualquer coisa, com o esforço necessário e as ferramentas certas.",
      url: "/aprender-a-aprender-meta-aprendizado/",
    },
    {
      image: MetaAprendizadoImg,
      title: "Meta-aprendizado",
      description:
        "É a análise do processo de aprendizagem. Trata-se de aprender a aprender.",
      url: "/aprender-a-aprender-meta-aprendizado/",
    },
    {
      image: AutodidatismoImg,
      title: "Autodidatismo",
      description:
        "É a habilidade de se instruir por esforço próprio, gerenciando seus processos de aprendizado, sem contar com auxílio de terceiros.",
      url: "/o-que-e-uma-pessoa-autodidata/",
    },
  ]

  return (
    <SC.Pillars>
      <Container
        isFlex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <SC.Heading>Os 4 pilares da Independência Intelectual:</SC.Heading>
        <SC.PillarsWrapper>
          {pillars.map(pillar => (
            <SC.Pillar key={pillar.title}>
              <SC.Image src={pillar.image} alt={pillar.title} />
              <SC.Title>{pillar.title}</SC.Title>
              <SC.Description>{pillar.description}</SC.Description>
              <SC.Link to={pillar.url}>Ler mais</SC.Link>
            </SC.Pillar>
          ))}
        </SC.PillarsWrapper>
      </Container>
    </SC.Pillars>
  )
}

SC.Pillars = styled.section`
  width: 100%;
  padding: 80px 0;
  background-color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding: 40px 25px;
  }
`

SC.Heading = styled.h2`
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-size: 30px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 26px;
    text-align: center;
  }

  span {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.PRIMARY_GREEN};
  }
`

SC.PillarsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
`

SC.Pillar = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
    margin: 40px 0;
  }
`

SC.Image = styled.img`
  width: 150px;
  height: 150px;
`

SC.Title = styled.span`
  font-size: 24px;
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 22px;
  }
`

SC.Description = styled.p`
  height: 120px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    height: auto;
  }
`

SC.Link = styled(Link)`
  background-color: ${({ theme }) => theme.colors.PRIMARY_GREEN};
  border-radius: ${({ theme }) => theme.sizes.ROUNDED_BORDER_RADIUS};
  color: #000;
  width: 130px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  transition: all 0.25s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`

export default Pillars

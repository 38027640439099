import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Container from "../../container"
import InstagramImg from "../../../images/social-icons/instagram.svg"
import TwitterImg from "../../../images/social-icons/twitter.svg"
import FacebookImg from "../../../images/social-icons/facebook.svg"
import LinkedinImg from "../../../images/social-icons/linkedin.svg"

const SC = {}

const ArticlesSocial = () => {
  const socialLinks = [
    {
      image: InstagramImg,
      url: "https://www.instagram.com/apetrecho.digital/",
    },
    {
      image: TwitterImg,
      url: "https://twitter.com/apetrecho_digi",
    },
    {
      image: FacebookImg,
      url: "https://www.facebook.com/apetrecho.digital/",
    },
    {
      image: LinkedinImg,
      url: "https://www.linkedin.com/company/apetrecho-digital/",
    },
  ]

  return (
    <SC.ArticlesSocial>
      <Container
        isFlex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <SC.Heading>Quer saber mais? Confira nossos artigos!</SC.Heading>
        <SC.LinkArticles to="/artigos/">VER TODOS OS ARTIGOS</SC.LinkArticles>
        <SC.HeadingSocial>Siga nossas redes sociais:</SC.HeadingSocial>
        <SC.SocialMediaLinks>
          {socialLinks.map(social => (
            <SC.SocialMedia to={social.url} key={social.url}>
              <SC.Image src={social.image} />
            </SC.SocialMedia>
          ))}
        </SC.SocialMediaLinks>
      </Container>
    </SC.ArticlesSocial>
  )
}

SC.ArticlesSocial = styled.section`
  width: 100%;
  padding: 80px 0;
  background-color: #fff;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding: 25px;
  }
`

SC.Heading = styled.h2`
  color: #000;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-size: 30px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 26px;
  }

  span {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.PRIMARY_GREEN};
  }
`

SC.LinkArticles = styled(Link)`
  background-color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};
  border-radius: ${({ theme }) => theme.sizes.ROUNDED_BORDER_RADIUS};
  font-weight: 600;
  color: #fff;
  width: 430px;
  font-size: 24px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  transition: all 0.25s ease-in-out;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
    font-size: 20px;
  }

  &:hover {
    opacity: 0.8;
  }
`

SC.HeadingSocial = styled(SC.Heading)`
  margin-top: 90px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    margin-top: 40px;
  }
`

SC.SocialMediaLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 33%;
  margin-top: 10px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }
`

SC.SocialMedia = styled(Link)`
  width: 80px;
  transition: all 0.25s ease-in-out;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 50px;
  }

  &:hover {
    opacity: 0.8;
  }
`

SC.Image = styled.img`
  width: 100%;
`

export default ArticlesSocial

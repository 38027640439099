import React from "react"
import styled from "styled-components"
import Container from "../../container"
import IntelectualIndependencyImg from "../../../images/quem-somos/independencia-intelectual.jpg"

const SC = {}

const IntelectualIndependency = () => (
  <SC.IntelectualIndependency>
    <SC.Container
      isFlex
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <SC.TextWrapper>
        <SC.Heading>
          Mas, afinal, o que é essa
          <br />
          tal Independência Intelectual?
        </SC.Heading>
        <SC.Description>
          Ser intelectualmente independente é{" "}
          <b>
            não precisar de outras pessoas para te ensinarem o que você precisa
            aprender
          </b>
          .
        </SC.Description>
        <SC.Description>
          É saber escolher os próprios objetos e métodos de aprendizagem, de
          acordo com seus princípios, valores e objetivos de vida.
        </SC.Description>
        <SC.Description>
          É assumir as rédeas da própria educação, fazendo as próprias escolhas
          com os olhos no futuro e a cabeça no presente.
        </SC.Description>
      </SC.TextWrapper>
      <SC.Image
        src={IntelectualIndependencyImg}
        alt="O que é Independência Intelectual?"
      />
    </SC.Container>
  </SC.IntelectualIndependency>
)

SC.IntelectualIndependency = styled.section`
  width: 100%;
  background-color: #fff;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding: 25px;
  }
`

SC.Container = styled(Container)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
  }
`

SC.Heading = styled.h2`
  color: #000;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-size: 30px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 26px;
    text-align: center;
  }

  span {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.PRIMARY_GREEN};
  }
`

SC.TextWrapper = styled.div`
  width: 40%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }
`

SC.Description = styled.p`
  color: ${({ theme }) => theme.colors.GREY_1};
  margin-left: 15px;

  &:before {
    content: "•";
    color: ${({ theme }) => theme.colors.GREY_1};
    position: absolute;
    left: 0;
  }
`

SC.Image = styled.img`
  width: 550px;
`

export default IntelectualIndependency
